<template>
  <el-row class="documents-ai-container">
    <el-col :span="4">
    </el-col>
    <el-col :span="16">
      <el-scrollbar class="messages" ref="messagesRef">
        <div  class="title" style="margin-bottom: 10px">氢能 AI</div>
        <div v-for="(message, index) in messages" :key="index" :class="['message', message.sender]">
          <div class="message-content">
            <div class="text" v-html="message.text">
            </div>
            <el-divider v-if="message.sender === 'bot' && message.doc_references != null && message.doc_references.length > 0" style="margin-top: 2px; margin-bottom: 2px; "></el-divider>
    
            <div v-if="message.sender === 'bot'" class="doc-references">
              <div v-for="(reference, index) in message.doc_references" :key="index" class="reference-item">
                <span class="blue-text">{{ reference }}</span>
              </div>
            </div>
            <!-- <img v-if="message.sender === 'bot'" src="path/to/bot-avatar.png" alt="Bot Avatar" class="avatar bot-avatar"> -->
            <!-- <img v-if="message.sender === 'user'" src="path/to/user-avatar.png" alt="User Avatar" class="avatar user-avatar"> -->
          </div>
        </div>
      </el-scrollbar>
      <el-card class="input-area">
        <textarea
        v-model="newMessage"
        style="width: 100%; border: none; resize: none; padding: 5px;"
        rows="3"
        placeholder="请输入您的问题"
        @keyup.enter="sendMessage"
      ></textarea>
        <el-row style="width: 100%;">
          <el-col :span="2" :offset="22">
            <el-button
            type="primary"
            :disabled="!newMessage"
            @click="sendMessage"
            :icon="Top"
            circle
            />
          </el-col>
        </el-row>
      </el-card>
    </el-col>
  </el-row>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { Top,Search,Position } from '@element-plus/icons-vue';

const newMessage = ref('');
const messages = ref([]);
const messagesRef = ref(null);



const sendMessage = async () => {
  if (newMessage.value.trim() === '') return;

  // 添加用户消息
  messages.value.push({ sender: 'user', text: newMessage.value });

  try {
    const response = await axios.post(
      '/aliyun/api/v1/apps/437480373756454e924c17b515f47968/completion',
      {
        input: {
          prompt: newMessage.value
        },
        parameters: {},
        debug: {}
      },
      {
        headers: {
          Authorization: 'Bearer sk-691e021d299c407da08f0522cb103bee',
          'Content-Type': 'application/json'
        }
      }
    );

    let cleanText = response.data.output.text;
    cleanText = cleanText.replace(/([。])(<ref>(.*?)<\/ref>)*/g, '$1$2<br><br>');
    cleanText = cleanText.replace(/<ref>(.*?)<\/ref>/g, '<span style="color: gray;">$1</span>');

    let doc_references = response.data.output.doc_references
    let showed_doc_references = []
    if (doc_references != null) {
      for (let i = 0; i < doc_references.length; i++) {
        showed_doc_references[i] = '[' + doc_references[i].index_id + ']' + doc_references[i].doc_name
      }
    }
    // 更新消息对象，添加 references 字段
    messages.value.push({ sender: 'bot', text: cleanText, doc_references: showed_doc_references });

    // console.log(response.data)
  } catch (error) {
    console.error('请求失败:', error);
  }

  // 清空输入框
  newMessage.value = '';

  // 滚动到底部
  messagesRef.value.scrollTop = messagesRef.value.scrollHeight;
};

let start_text = `
您好，我是企业调研AI小助手，请问您需要什么帮助？
`

onMounted(() => {
  // 初始滚动到底部
  messagesRef.value.scrollTop = messagesRef.value.scrollHeight;
  messages.value.push({ sender: 'bot', text: start_text });
});
</script>

<style scoped>
.documents-ai-container{
  height: 100vh;
  width: 100vw;
}

.messages {
  height: 75vh;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.message {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.message.user {
  justify-content: flex-end;
}

.message.bot {
  justify-content: flex-start;
}

.message-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-avatar {
  margin-left: 10px;
  margin-right: 0;
}

.text {
  width: 100%;
  text-align: left;
  font-size: 14px;
}
.doc-references {
  width: 100%;
  text-align: left;
}

.reference-item{
  width: 100%;
  text-align: left;
}

.message.user .message-content {
  background-color: #4649ff;
  color: #ffffff;
}

.message.bot .message-content {
  background-color: #f0f0f0;
  color: black;
}

.input-area {
  height: 20vh;
  border-radius: 15px;
}

textarea {
  outline: none; /* 移除聚焦时的边框 */
  background-color: #ffffff; /* 可以根据需要调整背景颜色 */
  font-size: 14px; /* 调整字体大小 */
  color: #333; /* 调整文字颜色 */
}

.blue-text {
  color: rgb(107, 107, 255);
  font-size: 12px;
}
</style>